import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environment';
import { SettingsService } from './settings.service';
import { isPlatformBrowser, KeyValue } from '@angular/common';

import * as Cookies from 'js-cookie';
import { Request, Response } from 'express';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import { Observable } from 'rxjs';
import { Token } from '../types/token';
import { ApiResponse } from '../types/apiResponse';
import { User } from '../types/user';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private tokenUrl = `${environment.API_URL}/v2/auth/token`; // URL to web api
  private refreshTokenUrl = `${environment.API_URL}/v2/auth/refresh/token`; // URL to web api

  constructor(private http: HttpClient) {}

  public static accessToken: Token;
  public static refreshToken: Token;

  async getTokenFromServer(userInfo: User): Promise<Token> {
    console.log('[AuthService getTokenFromServer]', 'UserInfo: ', userInfo);

    const apiResponse = await this.http
      .get<ApiResponse<Token>>(
        `${this.tokenUrl}?d_type=web&d_id=${userInfo.d_id}&g_id=${userInfo.g_id}`
      )
      .toPromise();

    AuthService.accessToken = apiResponse.data;

    return apiResponse.data;
  }

  public getToken(): string {
    const token = AuthService.accessToken;
    if (token) {
      return token.access_token;
    }
    return null;
  }

  public checkTokenIfExpired(token): boolean {
    return true;
  }

  public isAuthenticated(): boolean {
    // get the token
    const token = this.getToken();

    // return a boolean reflecting
    // whether or not the token is expired
    return this.checkTokenIfExpired(token);
  }
}
