export const environment = {
  production: true,
  environmentName: "Production",
  API_URL: "https://api.24trains.tv/api",
  SOCIAL_LOGIN: "https://api.24trains.tv/api",
  version: "v2/",
  v3_version: "v3/",
  ANGULAR_SITE_URL: "https://app.24trains.tv/",
  IMAGE_PATH_URL: "",
  IMAGE_PATH_URL_CONVERTED: "",
  TRANSLATE_PATH_URL: "",
  BASE_URL: "https://www.24trains.tv",
  COKKIE_DOMAIN: "api.24trains.tv",
  APP_NAME: "24trains",
  htmlAppName: "tv2z",
  indexFile: "index.html",
};
