import { Inject, Injectable, PLATFORM_ID, APP_ID, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// import { API_URL, TRANSLATE_PATH_URL } from '../../config.json';
import { environment } from 'src/environment';
import { BehaviorSubject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class LoaderService {

  public loaderSource = new BehaviorSubject(true);

}
