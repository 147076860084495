import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { LoaderService } from '../app/services/loader.service';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Filmbox';
  loading = true;
  appName;

  constructor(@Inject(PLATFORM_ID) private platformId: object, public loader : LoaderService) {
    this.loader.loaderSource.subscribe(data => {
      this.loading = data;
    });
  }

  ngOnInit(){
    this.appName = environment.APP_NAME;
    if (isPlatformBrowser(this.platformId)) {
      document.body.tabIndex = 0;
      document.body.focus();  
    }
  }
    //This is to generate random placeholder images
}
