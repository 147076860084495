import { Inject, Injectable, PLATFORM_ID, APP_ID } from '@angular/core';
import { Router,  ActivatedRoute, Routes, RouterModule } from '@angular/router';
// import { API_URL, COKKIE_DOMAIN, ANGULAR_SITE_URL } from '../../config.json';
import { environment } from 'src/environment';
import { SettingsService } from './settings.service';
import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';

import { AuthService } from './auth.service';
// import { LoaderService } from './loader.service';
import { CookieService } from 'ngx-cookie';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { UikitService } from './uikit.service';

declare var $: any;

@Injectable({ providedIn: 'root' })
export class AppService {
  locale:string = '';
  alllocales;
  body_scripts;
  google_analytics;
  paywall_dengage_script;
  header_scripts;
  region: any;
  country_code: any;
  playerOptionUrl;
  constructor(
    private authService: AuthService,
    private router: Router,
    public route: ActivatedRoute,
    public location: Location,
    private uikitService: UikitService,
    //public loader : LoaderService,
    private http: HttpClient,
    private settingsService: SettingsService,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(APP_ID) private appId: string,
    private cookieService: CookieService,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  async init(): Promise<void> {
      //this.loader.loaderSource.next(true);
      const settings: any = await this.settingsService.getSettings();
      if(!settings) {
        return
      };
      this.settingsService.regionTv = this.settingsService?.userSettingsData?.configuration.region['region_code'];
      this.alllocales = this.settingsService.getLocale();
      this.region = this.settingsService.getRegion();
      this.playerOptionUrl = settings.settings.player_language_options;
      if(settings.settings.geo_blocking_enabled) {
        this.country_code = this.settingsService.getCountryCode();
      }
      // await this.authService.getTokenFromServer(settings.user);
      const notAvailable = settings.settings.not_available_region;
      if(notAvailable && notAvailable !='' && notAvailable.includes(this.region.region_code)){
        this.router.navigateByUrl('/not-available');
      }
      if(isPlatformBrowser(this.platformId)) {
        if(window.location.href.includes('snappet') || window.location.href.includes('hoopla')) 
        {
          const url = new URL(window.location.href);
          let pathAndQuery = url.pathname + url.search;
          if (pathAndQuery.startsWith('/')) {
            pathAndQuery = pathAndQuery.slice(1); // Remove the leading '/'
          }
          window.location.href = environment.ANGULAR_SITE_URL + pathAndQuery;
        }
        const u_id = localStorage.getItem('u_id');
        const g_id = localStorage.getItem('g_id');
        const d_id = localStorage.getItem('d_id');
        const hoopla_user = localStorage.getItem('hoopla_user');
        const universal_user = localStorage.getItem('universal_user');
        const trans_id = localStorage.getItem('hoopla_transaction_id');
        const hoopla_start_time = localStorage.getItem('hoopla_start_time');
        const hoopla_end_time = localStorage.getItem('hoopla_end_time');
        if(g_id == null && d_id == null) {
          document.cookie = 'd_id=; domain='+environment.COKKIE_DOMAIN+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          document.cookie = 'g_id=; domain='+environment.COKKIE_DOMAIN+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
        if(g_id) {
          let cookiegName = 'g_id';
          let cookiegValue = g_id;
          document.cookie = cookiegName +"=" + cookiegValue + ";domain="+environment.COKKIE_DOMAIN+";path=/";
        }
        if(d_id) {
          let cookiedName = 'd_id';
          let cookiedValue = d_id;
          document.cookie = cookiedName +"=" + cookiedValue + ";domain="+environment.COKKIE_DOMAIN+";path=/";
        }
        if(u_id) {
          let cookieuName = 'u_id';
          let cookieuValue = u_id;
          document.cookie = cookieuName +"=" + cookieuValue + ";domain="+environment.COKKIE_DOMAIN+";path=/";
        }
        if(hoopla_user) {
          let cookieuName = 'hoopla_user';
          let cookieuValue = hoopla_user;
          document.cookie = cookieuName +"=" + cookieuValue + ";domain="+environment.COKKIE_DOMAIN+";path=/";
        }
        if(universal_user) {
          let cookieuName = 'universal_user';
          let cookieuValue = universal_user;
          document.cookie = cookieuName +"=" + cookieuValue + ";domain="+environment.COKKIE_DOMAIN+";path=/";
        }
        if(trans_id) {
          let cookieuName = 'hoopla_transaction_id';
          let cookieuValue = trans_id;
          document.cookie = cookieuName +"=" + cookieuValue + ";domain="+environment.COKKIE_DOMAIN+";path=/";
        }
        if(hoopla_start_time) {
          let cookieuName = 'hoopla_start_time';
          let cookieuValue = hoopla_start_time;
          document.cookie = cookieuName +"=" + cookieuValue + ";domain="+environment.COKKIE_DOMAIN+";path=/";
        }
        if(hoopla_end_time) {
          let cookieuName = 'hoopla_end_time';
          let cookieuValue = hoopla_end_time;
          document.cookie = cookieuName +"=" + cookieuValue + ";domain="+environment.COKKIE_DOMAIN+";path=/";
        }
        const u_cokkie_id = this.cookieService.get('u_id');
        if(u_cokkie_id || u_id) {
          let utm_url = this.settingsService.getSetUTMValues(environment.ANGULAR_SITE_URL)
          if(!window.location.href.includes('tv-guide')) window.location.href = utm_url;
        } else {  
          localStorage.removeItem('u_id');
          localStorage.removeItem('g_id');
          localStorage.removeItem('d_id');
        }
        //this.loader.loaderSource.next(true);
        if (this.location.path() != '') {
          this.locale = this.getLocale(this.location.path());
        }
        const storedLocale = localStorage.getItem('locale');
        if (this.locale) {
          localStorage.setItem('locale', this.locale);
        } else if (storedLocale) {
          this.locale = storedLocale;
        } else {
          const userSettings = this.settingsService?.userSettingsData?.configuration.region;
          this.locale = userSettings['locale'];
          localStorage.setItem('locale', this.locale);
          localStorage.setItem('localeName', userSettings['language']);
        }

        this.alllocales.find(locale => {
            if(locale.lang_code === this.locale) {
            this.getPlayerOptions().subscribe(async (playerOptionsText) => {
              if (playerOptionsText) {
                  if (playerOptionsText.hasOwnProperty(locale.lang_code.trim().split('-')[0])) {
                    locale.translatedLanguage = playerOptionsText[locale.lang_code.trim().split('-')[0]].iso_language_name;
                  } else {
                    locale.translatedLanguage = locale.lang_name;
                  }
                  localStorage.setItem('localeName', locale.translatedLanguage);
              }
            });
            }
        });
        
        this.document.documentElement.lang = this.locale?.substring(0, 2).toUpperCase();
        let cookieName = 'locale';
        let cookieValue = this.locale;
        document.cookie = cookieName +"=" + cookieValue + ";domain="+environment.COKKIE_DOMAIN+";path=/";
        this.google_analytics = settings.settings.google_analytics;
        this.body_scripts = settings.settings.body_scripts;
        this.paywall_dengage_script = settings.settings.paywall_dengage_script;
        this.header_scripts = settings.settings.header_scripts;
        $('head').prepend(this.google_analytics);
        $('head').prepend(this.header_scripts);
        $('head').prepend(this.paywall_dengage_script);
        $('body').prepend(this.body_scripts);
      }
  }
  getPlayerOptions() {
    return this.http.get(this.playerOptionUrl)
      .pipe(
        map(
          (response: any) => {
            return response;
          }), catchError((error) => {
            if (error.error && error.error.code !== 1002) {
              this.uikitService.notifyError(error);
              //this.router.navigateByUrl('404');
            }
            return error;
          })
      );
  }

  getLocale(url: string): string | null {
    const localeRegex = /locale=([a-z]{2}-[A-Z]{2})/;
    const match = url.match(localeRegex);
    if (match && match[1]) {
      return match[1];
    }
    return null;
  }
}
